import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/(home)/layout.tsx\",\"import\":\"Literata\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-literata\"}],\"variableName\":\"literataGaramond\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/node_modules/next/font/google/target.css?{\"path\":\"src/app/(home)/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-roboto\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/stayinkova.com/nextjs/src/app/globals.scss");
